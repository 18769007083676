import isInBrowser from '@helpers/is-in-browser'
import { Tab, Tabs, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { Accordion, H1, H2, H3, H4, H5, H6 } from '@system'
import PageContainer from '@system/page-container'
import ACC_THEME from '@themes'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'

const useTabStyles = makeStyles((theme) => {
  return {
    tabRoot: ({ selected, titleCase, variant }) => ({
      color:
        variant === 'vertical' || selected
          ? theme.palette.primary.main
          : theme.palette.text.tertiary,
      display: 'flex',
      fontSize: '16px',
      fontWeight:
        variant !== 'vertical' || selected
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightRegular,
      height: variant !== 'vertical' ? '48px' : 'unset',
      justifyContent: 'space-between',
      margin: '0',
      minHeight: variant !== 'vertical' ? '48px' : 'unset',
      minWidth: 'unset',
      textTransform: titleCase || variant === 'vertical' ? 'none' : 'uppercase',
      '& .MuiTab-wrapper': {
        height: '100%',
        width: 'auto',
        zIndex: '100',
      },
      '& div.MuiTabs-scroller': {
        overflowY: 'hidden',
      },
      [theme.breakpoints.down('lg')]: {
        textAlign: variant === 'vertical' && 'left',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
      },
    }),
  }
})

const useTabsModuleStyles = makeStyles((theme) => {
  return {
    tabsModuleTitle: {
      justifyContent: 'center',
      maxWidth: '1056px',
      margin: '0 auto',
      display: 'flex',
      paddingTop: '32px',
      paddingBottom: '32px',
      textAlign: 'center',
    },
    tabsModuleContainer: ({ variant }) => ({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      margin: '0 auto',
      maxWidth: '1060px',
      padding: '0 16px',
      [theme.breakpoints.up('lg')]: {
        flexDirection: variant === 'vertical' && 'row',
        gap: variant === 'vertical' && '16px',
      },
    }),
    tabsModuleRoot: {
      alignItems: 'center',
      backgroundImage: 'linear-gradient(#E4E4E4, 4px, #ffffff 0%)',
      backgroundPosition: 'right 0px bottom 4px',
      display: 'flex',
      height: '48px',
      minHeight: '48px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'hidden',
        height: 'fit-content',
      },
      '& > div > div': {
        justifyContent: 'center',
      },
    },
    tabsModuleRootVariantVertical: {
      minHeight: 'unset',
      [theme.breakpoints.up('lg')]: {
        flex: 'none',
        padding: '32px 40px 32px 0',
        position: 'relative',
        width: 'auto',
        '& > div > div': {
          gap: '16px',
          justifyContent: 'center',
        },
        '&::after': {
          background: theme.palette.border.gray,
          content: '""',
          height: '100%',
          position: 'absolute',
          right: '0',
          top: '0',
          width: '2px',
        },
      },
    },
    tabsModuleIndicator: ({ variant }) => ({
      backgroundColor:
        variant === 'accent'
          ? theme.palette.background.iris
          : theme.palette.background.dark,
      height: '3px',
      width: '100%',
      '&::before': {
        backgroundColor:
          variant === 'accent'
            ? theme.palette.background.iris
            : theme.palette.background.dark,
        content: '""',
        display: 'block',
        height: '26px',
        width: '26px',
        margin: '0 auto',
        transform: 'rotate(-45deg)',
      },
    }),
    tabsModuleIndicatorVariantVertical: {
      backgroundColor: theme.palette.background.iris,
      height: '100%',
      right: 'unset',
      width: '3px !important',
    },
    tabsModuleHrContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-24px',
      gap: '24px',
    },
    tabsModuleHr: {
      background: theme.palette.border.gray,
      border: 'none',
      height: '1px',
      margin: '0',
    },
    tabsModuleCta: {
      alignItems: 'center',
      color: theme.palette.common.black,
      display: 'flex',
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',
      '& img': {
        height: '22px',
        width: '22px !important',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    tabsModuleContent: {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        paddingBottom: '48px',
      },
    },
    tabsModuleContentVariantVertical: {
      width: '100%',
      '& a': {
        alignItems: 'center',
        color: theme.palette.common.black,
        display: 'flex',
        fontSize: '16px',
        textDecoration: 'none',
        '& img': {
          height: '22px',
          width: '22px !important',
        },
        '&:hover': {
          color: theme.palette.common.black,
          textDecoration: 'underline',
        },
      },
      [theme.breakpoints.down('lg')]: {
        paddingBottom: '48px',
      },
    },
    tabsModuleAccordionContainer: {
      padding: '24px 16px 56px',
      // Hotfix to hide twoColHighlightsWithImage title
      '& h3': {
        display: 'none',
      },
    },
  }
})

const TabsModuleTab = ({ isLink, selected, titleCase, variant, ...props }) => {
  const classes = useTabStyles({
    selected,
    titleCase,
    variant,
  })

  return (
    <div>
      <Tab
        classes={{
          root: classes.tabRoot,
        }}
        component={'span'}
        onClick={(event) => {
          event.preventDefault()
        }}
        {...props}
      >
        {props.children}
      </Tab>
    </div>
  )
}

const renderTitle = (renderTitleAs, text) => {
  switch (renderTitleAs) {
    case 'h1':
      return (
        <H1 color="secondary" component="h2">
          {text}
        </H1>
      )
    case 'h2':
      return <H2 color="secondary">{text}</H2>
    case 'h3':
      return <H3 color="secondary">{text}</H3>
    case 'h4':
      return <H4 color="secondary">{text}</H4>
    case 'h5':
      return <H5 color="secondary">{text}</H5>
    case 'h6':
      return <H6 color="secondary">{text}</H6>
    default:
      return <H3 color="secondary">{text}</H3>
  }
}

const TabsModule = ({ blok }) => {
  const { cta, renderTitleAs, title, type, variant, tabs } = blok
  const [value, setValue] = useState(0)
  const classes = useTabsModuleStyles({ variant })
  const isHandHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const isDesktopDevice = useMediaQuery(ACC_THEME.breakpoints.up('lg'))

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (isInBrowser) {
      !!tabs &&
        tabs.length > 0 &&
        tabs.forEach((element) => {
          if (
            window.location.href.split('#').reverse()[0] === element.anchorId
          ) {
            setValue(Number(element.anchorId))
          }
        })
    }
  }, [])

  return (
    <SbEditable content={blok}>
      <PageContainer>
        {!!title && (
          <div className={classes.tabsModuleTitle}>
            {renderTitle(renderTitleAs, title)}
          </div>
        )}
        {isHandHeldDevice && variant === 'vertical' ? (
          <>
            {cta?.length > 0 &&
              renderBloks(cta, {
                className: classes.tabsModuleCta,
              })}
            <div className={classes.tabsModuleAccordionContainer}>
              {tabs?.map((tab) => (
                <Accordion
                  key={tab._uid}
                  title={tab.title}
                  variant="tabsModule"
                >
                  {renderBlok(tab)}
                </Accordion>
              ))}
            </div>
          </>
        ) : (
          <div className={classes.tabsModuleContainer}>
            <Tabs
              aria-label="Features"
              classes={{
                root: classNames({
                  [classes.tabsModuleRoot]: variant !== 'vertical',
                  [classes.tabsModuleRootVariantVertical]:
                    variant === 'vertical',
                }),
                indicator: classNames({
                  [classes.tabsModuleIndicator]: variant !== 'vertical',
                  [classes.tabsModuleIndicatorVariantVertical]:
                    variant === 'vertical',
                }),
              }}
              onChange={handleChange}
              orientation={
                isDesktopDevice && variant === 'vertical'
                  ? 'vertical'
                  : 'horizontal'
              }
              value={value}
              variant={type || 'fullWidth'}
            >
              {tabs?.map((tab, index) => {
                return (
                  <TabsModuleTab
                    id={tab.anchorId}
                    key={tab._uid}
                    label={tab.title}
                    selected={index === value}
                    titleCase={tab.titleCase}
                    variant={variant}
                  />
                )
              })}
              {variant === 'vertical' && cta?.length > 0 && isDesktopDevice && (
                <>
                  <hr className={classes.tabsModuleHr} />
                  {renderBloks(cta, {
                    className: classes.tabsModuleCta,
                  })}
                </>
              )}
            </Tabs>
            {variant === 'vertical' && cta?.length > 0 && !isDesktopDevice && (
              <div className={classes.tabsModuleHrContainer}>
                <hr className={classes.tabsModuleHr} />
                {renderBloks(cta, {
                  className: classes.tabsModuleCta,
                })}
              </div>
            )}
            <div
              className={classNames({
                [classes.tabsModuleContent]: variant !== 'vertical',
                [classes.tabsModuleContentVariantVertical]:
                  variant === 'vertical',
              })}
            >
              {renderBlok(tabs[value])}
            </div>
          </div>
        )}
      </PageContainer>
    </SbEditable>
  )
}

export default TabsModule
